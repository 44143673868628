<template>
  <div class="container">
      <div class="main">
          <div style="overflow: hidden"></div>
          <div class="register-main">
              <div class="register-main-content">
                  <div class="login-title">权限申请</div>

                  <div class="register-input">
                      <el-form
                          :model="addForm"
                          ref="form"
                          label-width="80px"
                          label-position="top"
                          :rules="rules"
                      >
                          <el-form-item label="邮箱:" prop="email">
                              <el-input
                                  placeholder="请输入您的邮箱"
                                  v-model="addForm.email"
                              >
                                  <i
                                      slot="prefix"
                                      class="el-input__icon el-icon-message"
                                  ></i>
                              </el-input>
                          </el-form-item>
                          <el-form-item label="姓名:">
                              <el-input
                                  placeholder="请输入您的姓名"
                                  v-model="addForm.name"
                              >
                                  <i
                                      slot="prefix"
                                      class="el-input__icon el-icon-s-custom"
                                  ></i>
                              </el-input>
                          </el-form-item>
                          <el-form-item label="权限类型:">
                                <el-input
                                    placeholder="请输入权限类型"
                                    v-model="addForm.username"
                                >
                                    <i
                                        slot="prefix"
                                        class="
                                            el-input__icon
                                            el-icon-user-solid
                                        "
                                    ></i>
                                </el-input>
                            </el-form-item>
                          <el-form-item label="联系电话:">
                              <el-input
                                  placeholder="请输入联系电话"
                                  v-model="addForm.tel"
                              >
                                  <i
                                      slot="prefix"
                                      class="el-input__icon el-icon-phone"
                                  ></i>
                              </el-input>
                          </el-form-item>
                          <el-form-item label="权限管理" :label-width="formLabelWidth">
                    <el-checkbox-group v-model="editAccess">
                        <el-checkbox label="COSMAP" name="type"></el-checkbox>
                        <el-checkbox label="热处理优化系统" name="type"></el-checkbox>
                        <el-checkbox label="设备库" name="type"></el-checkbox>
                        <el-checkbox label="材料库" name="type"></el-checkbox>
                        <el-checkbox label="工艺优化库" name="type"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                      </el-form>
                  </div>
                  <div class="login-main-right-button">
                      <el-button
                          style="width: 100%; font-size: 18px"
                          type="danger"
                          @click="daochuword"
                          >导出word</el-button
                      >
                  </div>
              </div>
          </div>

          <div class="footer">
              <div class="info">版权所有：&copy; COSMAP</div>
              <div class="info">中丨English丨日本語</div>
          </div>
      </div>
  </div>
</template>
<script>
import {
  RegisterUser,
  SendEmailToRegister,
  RegisterEmailValidation,
  toword,
} from "../api/api";
import Sidentify from "../components/Sidentify"; //**引入验证码组件**
export default {
  components: {
      "v-sidentify": Sidentify,
  },
  data() {
      return {
          dialogVisible: false,
          validationEmaildialogVisible: false,
          verificationCode: "",
          validationEmailCode: "",
          agreementState: false,

          addForm: {
              email: null,
              username: "",
              password: "",
              name: "",
              tel: "",
              address: "",
          },
          rules: {
              email: [
                  {
                      required: true,
                      message: "邮箱不能为空",
                      trigger: "blur",
                  },
                  {
                      required: true,
                      pattern:
                          /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
                      message: "邮箱格式异常",
                      trigger: "blur",
                  },
              ],
              username: [
                  {
                      required: true,
                      message: "邮箱不能为空",
                      trigger: "blur",
                  },
              ],
              password: [
                  {
                      required: true,
                      message: "邮箱不能为空",
                      trigger: "blur",
                  },
              ],
          },
          formLabelWidth: "120px",
      };
  },
  methods: {
      WatchAgreement() {
          this.dialogVisible = true;
      },
      AgreeAgreement() {
          this.agreementState = true;
          this.dialogVisible = false;
      },
      validationEmailClick() {},
      validationEmail() {
          let validateFlag = false;
          let parms = {
              Email: this.addForm.email,
              Code: this.validationEmailCode,
          };
          RegisterEmailValidation(parms).then((res) => {
              if (res == true) {
                  RegisterUser(this.addForm).then((res) => {
                      if (res == true) {
                          this.$message({
                              message:
                                  "注册成功，请等待管理员审核，即将在5秒后跳转至主页登陆",
                              type: "success",
                          });
                          setTimeout(() => {
                              this.$router.push({
                                  path: "/",
                              });
                          }, 5000);
                      } else {
                          this.$message.error("注册失败，请联系管理员！");
                      }
                      this.validationEmaildialogVisible = false;
                  });
              } else {
                  this.$message.error("您的验证码输入有误！");
              }
          });
      },
      //点击确认添加按钮
      RegisterConfirm() {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  console.log(valid);
              } else {
                  return;
              }
          });
          if (this.agreementState == false) {
              this.$message({
                  message: "请先接受用户协议",
                  type: "warning",
              });
              return;
          }
          var parms = {
              Email: this.addForm.email,
          };
          SendEmailToRegister(parms).then((res) => {
              if (res == true) {
                  this.$message({
                      message: "已将验证码发送到您的邮箱",
                      type: "success",
                  });
                  this.validationEmaildialogVisible = true;
              } else if(res == null){
                  this.$message.error("注册失败，请联系管理员！");
              }
              else
              {
                this.$message.error("请联系管理员！");
              }
          });
      },
      daochuword(){
        toword().then((res) => {
              if (res == true) {
                  this.$message({
                      message: "已将验证码发送到您的邮箱",
                      type: "success",
                  });               
              } else {
                  this.$message.error("注册失败，请联系管理员！");
              }
          });
      },
      // handleClose(done) {
      //     this.$confirm("")
      //         .then((_) => {
      //             done();
      //         })
      //         .catch((_) => {});
      // },
  },
};
</script>
<style scoped>
.main {
  background-color: #4780ff;
  height: 60vh;
  background-image: url("../assets/loginBackground.png");
  background-size: 100% 100%;
}
.main .register-main {
  margin: 10vh auto 5vh auto;
  padding-bottom: 10px;
  background-color: white;

  width: 900px;
  border-radius: 2px;
  border: 1px solid #ebedf0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 3px rgba(0, 0, 0, 0.04);
}

.register-main-content {
  margin: 0 auto;

  width: 500px;
  height: 100%;

  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.login-main-right-button {
  width: 100%;
  height: 70px;
  margin-bottom: 30px;
}

.login-main-right-button a {
  color: #8492a6;
  font-size: 15px;
}
.register-input {
  min-height: 190x;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.login-title {
  padding-top: 30px;

  text-align: center;
  color: #f56c6c;
  font-size: 26px;
  font-weight: 550;
  border-bottom: 1px solid #f56c6c;
  height: 45px;
  margin-bottom: 10px;
}
.sidentifyContent {
  display: flex;
  flex-direction: row;
}
.sidentifyContent .el-input__icon {
  height: auto;
}
.footer {
  background-color: white;
  text-align: center;

  height: 60px;
}
.footer .info {
  font-size: 15px;
  color: #8492a6;
  width: 200px;
  margin: 0 auto;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>